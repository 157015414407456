
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$embedColorBackground: #00244a;
$embedColorText: #ffffff;
$embedColorArrow: #ffffff;
$embedColorVideoYoutube: #f00;
$embedColorVideoVimeo: #17d5ff;
$embedFontDesktopText: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$embedFontMobileText: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
