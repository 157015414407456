
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$accordionFontFilterDesktopTitle: 700 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$accordionFontFilterDesktopContent: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$accordionFontFilterMobileTitle: 700 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$accordionFontFilterMobileContent: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$accordionFontContentDesktopTitle: 100 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$accordionFontContentDesktopText: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$accordionFontContentMobileTitle: 100 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$accordionFontContentMobileText: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$accordionColorTitle: #00244a;
$accordionColorText: #5f666d;
$accordionColorSeparator: #cad7e3;
$accordionColorBullets: #5f666dB3;
$accordionColorButtonBackground: #00244a;
$accordionColorButtonIcon: #ffffff;
$accordionColorHoverFocus: #005ca9;
