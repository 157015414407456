
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$highlightFontDesktopTitle: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$highlightFontDesktopDescription: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$highlightFontMobileTitle: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$highlightFontMobileDescription: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$highlightColorBackgroundDark: #00244a;
$highlightColorBackgroundAccent: #005ca9;
$highlightColorTextDefault: #ffffff;
$highlightColorTextBullets: rgba(255,255,255,0.7);
