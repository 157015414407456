
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$stepperColorBackgroundFinished: #00244a;
$stepperColorBackgroundCurrent: #005ca9;
$stepperColorBackgroundFutur: #ffffff;
$stepperColorInfoFinished: #ffffff;
$stepperColorInfoCurrent: #ffffff;
$stepperColorInfoFutur: #00244a;
$stepperColorSeperator: #005ca9;
$stepperFontInfo: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
