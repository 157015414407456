
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$buttonColorBackgroundPrimaryOnLight: #005ca9;
$buttonColorBackgroundPrimaryOnDark: #ffffff;
$buttonColorBackgroundPrimaryHoverFocusOnLight: #00244a;
$buttonColorBackgroundPrimaryHoverFocusOnDark: #f9f9f9;
$buttonColorBackgroundSecondaryOnLight: #ffffff;
$buttonColorBackgroundSecondaryOnDark: #ffffff00;
$buttonColorBackgroundSecondaryHoverFocusOnLight: #005ca9;
$buttonColorBackgroundSecondaryHoverFocusOnDark: #f0f1f21a;
$buttonColorBackgroundQuartenaryHoverFocusOnLight: #005ca9;
$buttonColorBackgroundQuartenaryHoverFocusOnDark: #ffffff;
$buttonColorBorderSecondaryOnLight: #00244a;
$buttonColorBorderSecondaryOnDark: #ffffff;
$buttonColorBorderSecondaryHoverFocusOnLight: #005ca9;
$buttonColorBorderSecondaryHoverFocusOnDark: #f0f1f2;
$buttonColorTextPrimaryOnLight: #ffffff;
$buttonColorTextPrimaryOnDark: #005ca9;
$buttonColorTextPrimaryHoverFocusOnLight: #ffffff;
$buttonColorTextPrimaryHoverFocusOnDark: #005ca9;
$buttonColorTextSecondaryOnLight: #00244a;
$buttonColorTextSecondaryOnDark: #ffffff;
$buttonColorTextSecondaryHoverFocusOnLight: #ffffff;
$buttonColorTextSecondaryHoverFocusOnDark: #f0f1f2;
$buttonColorTextTertiaryOnLight: #005ca9;
$buttonColorTextTertiaryOnDark: #ffffff;
$buttonColorTextTertiaryHoverFocusOnLight: #00244a;
$buttonColorTextTertiaryHoverFocusOnDark: #f0f1f2;
$buttonColorTextQuartenaryOnLight: #005ca9;
$buttonColorTextQuartenaryOnDark: #ffffff;
$buttonColorTextQuartenaryHoverFocusOnLight: #ffffff;
$buttonColorTextQuartenaryHoverFocusOnDark: #005ca9;
$buttonFontDefault: 600 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
