
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$headerColorText: #ffffff;
$headerColorDesktopBackground: linear-gradient(90deg, #00244ACC 50%, #005CA9CC 100%);
$headerColorTabletMobileBackground: linear-gradient(90deg, #00244A 50%, #005CA9 100%);
$headerColorTabDesktopMobile: #cad7e3;
$headerFontDesktopTitle: 400 44px/1.5 Roboto, Arial, Helvetica, sans-serif;
$headerFontDesktopDescription: 400 24px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$headerFontMobileTitle: 400 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$headerFontMobileDescription: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
