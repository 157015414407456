
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$formFontDesktopText: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontDesktopElementTitle: 600 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontDesktopTitle: 100 44px/1.5 Roboto, Arial, Helvetica, sans-serif;
$formFontDesktopAdditionalInfo: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontDesktopDescription: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontDesktopSubtitle: 700 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontDesktopErrorTitle: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$formFontDesktopErrorDescription: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontMobileText: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontMobileElementTitle: 600 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontMobileTitle: 100 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$formFontMobileAdditionalInfo: 400 14px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontMobileDescription: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontMobileSubtitle: 700 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontMobileErrorTitle: 500 18px/1.5 Roboto, Arial, Helvetica, sans-serif;
$formFontMobileErrorDescription: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formFontPlaceholder: 400 14px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$formColorActionBorderDefault: #cad7e3;
$formColorActionBorderError: #DD0000;
$formColorActionBackground: #ffffff;
$formColorActionIcon: #005ca9;
$formColorActionHoverFocus: #005ca9;
$formColorActionDarkBackground: #00244a;
$formColorActionDarkIcon: #ffffff;
$formColorInputFieldOnLightHoverFocus: #005ca9;
$formColorInputFieldOnLightBackground: #edf5fa;
$formColorInputFieldOnLightBorder: #edf5fa;
$formColorInputFieldInput: #00244a;
$formColorInputFieldIcon: #00244a;
$formColorInputFieldPlaceholder: #00244AB3;
$formColorInputFieldDisabled: #f9f9f9;
$formColorInputFieldError: #DD0000;
$formColorInputFieldOnDarkHoverFocus: #cad7e3;
$formColorInputFieldOnDarkBackground: #ffffff;
$formColorInputFieldOnDarkLabel: #ffffff;
$formColorInputFieldOnDarkBorder: #ffffff;
$formColorTitle: #00244a;
$formColorLabel: #5f666d;
$formColorAdditionalInfo: #5f666dB3;
$formColorDescription: #5f666d;
$formColorLink: #005ca9;
$formColorError: #DD0000;
