
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$secondaryNavigationColorBackground: #f9f9f9;
$secondaryNavigationColorTitle: #00244a;
$secondaryNavigationColorBorder: #cad7e3;
$secondaryNavigationColorLink: #005ca9;
$secondaryNavigationFontMobileLink: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$secondaryNavigationFontMobileTitle: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$secondaryNavigationFontDesktopLink: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$secondaryNavigationFontDesktopTitle: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
