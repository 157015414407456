
// Do not edit directly
// Generated on Fri, 07 Mar 2025 15:11:33 GMT

$controlsColorIcon: #00244a;
$controlsColorHoverFocus: #005ca9;
$controlsColorBorder: #cad7e3;
$controlsColorIconReversed: #ffffff;
$controlsColorBackground: #ffffff;
$controlsColorGradient: linear-gradient(90deg, #FFFFFF00 00 0%, #FFFFFF 100%);
